import React from "react";
import { Layout } from "../components/layout/Layout";
import { PrototypeArticle } from "../components/sections/insight/Prototype/PrototypeArticle";
import { MetaTags } from "../components/MetaTags";
import { PrototypeHero } from "../components/sections/insight/Prototype/PrototypeHero";

function InsightPrototypePage() {
  return (
    <Layout colorScheme="secondary" heroSection={<PrototypeHero />} isHeroShortBaseOnImage isWhite>
      <PrototypeArticle></PrototypeArticle>
    </Layout>
  )
}

export default InsightPrototypePage;

export const Head = () => (
  <MetaTags title="Transform your concepts into reality with our prototyping expertise " />
)