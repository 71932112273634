import React, { ReactNode } from "react";
import { Box, Flex } from "@chakra-ui/react";
import { Section } from "../../../layout/Section";
import ArticleHeading from "../../../ArticleHeading";
import { StaticImage } from "gatsby-plugin-image";
import ImageText from "../../../ImageText";
import PicturePara from "../../../PicturePara";
import HeadPara from "../../../HeadPara";
import { ContactCard } from "../../../cards/ContactCard";
import ArticleTag from "../../../ArticleTag";
import { BodyText } from "../../../texts/BodyText";
import AlignmentContainer from "../../../AlignmentContainer";
import ArticleImageContainer from "../../../ArticleImageContainer";
import ArticleTags from "../../../ArticleTags";
import { ContactMaricaCard } from "../../../cards/ContactMaricaCard";

export const PrototypeArticle: React.FC = () => {
  return (
    <Section>
      <Flex direction="column" gap="4rem">
        <AlignmentContainer>
          <ArticleHeading subtitle="Transform your concepts into reality with our prototyping expertise " />
          <ArticleTags>
            <ArticleTag tag="Category" value="Prototype, UX design" />
          </ArticleTags>
          <BodyText>
            Want to see your digital ideas come to life before the development
            process even starts? Then a prototype is the perfect solution for
            you! With a prototype you can bring your ideas to life, test them,
            and refine them until they're ready for the world.
          </BodyText>
        </AlignmentContainer>

        <ArticleImageContainer>
          <StaticImage
            alt=""
            src="../../../../images/Cover-prototyping.jpg"
            placeholder="none"
          />
          <AlignmentContainer>
            <ImageText
              text="A prototype is a preliminary visual mock-up of 
        an app that displays its design and core features, but lacks operational code."
            />
          </AlignmentContainer>
        </ArticleImageContainer>

        <AlignmentContainer>
          <HeadPara medium para="Simulation of your digital product" />
          <PicturePara
            para="  A prototype is a preliminary visual representation of a digital product that showcases its design and main features but doesn't contain functioning code.
            It gives the appearance of the final product but is just a simulation.
            Prototypes come in various levels of detail and accuracy and choosing the right one depends on your goals and the stage of ideation."
          />
        </AlignmentContainer>

        <ArticleImageContainer>
          <StaticImage
            alt=""
            src="../../../../images/02-prototype.png"
            placeholder="none"
          />
          <AlignmentContainer>
            <ImageText text="Key steps to a successful prototyping process." />
          </AlignmentContainer>
        </ArticleImageContainer>

        <AlignmentContainer>
          <HeadPara medium para="Convert your idea into the final product" />
          <PicturePara
            para="An essential aspect of prototyping is the ability to convert your idea into the final product.
            This requires collaboration with designers and developers who can assist you in building the desired prototype without writing any code."
          />
          <PicturePara
            para="Indevit approached the integration with readiness to assist, maintaining simple and close communication. Despite no prior knowledge of the systems, collaboration was smooth, thanks to Entia's detailed information. Indevit chose a tailored solution after consulting with Entia, prioritizing cost-effectiveness.
         Utilizing Entia's existing infrastructure minimized costs, highlighting a strategic and economical approach."
          />
        </AlignmentContainer>

        <ArticleImageContainer>
          <StaticImage
            alt=""
            src="../../../../images/03-prototype.png"
            placeholder="none"
          />
          <AlignmentContainer>
            <ImageText text="Our team of experts can help you with digital products from idea to launch. " />
          </AlignmentContainer>
        </ArticleImageContainer>

        <AlignmentContainer>
          <HeadPara medium para="From idea to launch" />
          <BodyText>
            At Indevit, our team of experts in software products can help you
            create a visual prototype of your idea from the very beginning. If
            you're interested in learning more about our prototyping services,
            feel free to reach out!
          </BodyText>
        </AlignmentContainer>

        <AlignmentContainer alignSelf="flex-start" marginTop="6.25rem">
          <HeadPara medium para="Do you want to know more? Contact us!" />
          <ContactMaricaCard />
        </AlignmentContainer>
      </Flex>
    </Section>
  );
};
